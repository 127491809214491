import React, { useEffect, Fragment } from "react"
import ImageDarkTextRightWithButton from "../components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "../components/imageDarkTextLeftWithButton"
import CTABusiness from "../../../general/ctaBusiness"
import FAQList from '../../../general/faqsList';
import InnerPageHeader from "../components/innerPageHeader"
import { scrollToElement } from "../../../../../utility/utils"
import DarkBelowFoldCards from "../../../general/darkBelowFoldCards";
import MoreForYouComponent from "../../../general/more-for-you";
import { moreForYou2 } from "../features";

// hero image
import EntryIllustration from '../../../../../../assets/img/sendmoney-hero-image.svg';
import EntryIllustrationBlur from "../../../../../../assets/img/business/blur/sendmoney-hero.inline.svg"

import SendIcon from "../../../../../../assets/img/kuda-send-icon.inline.svg"
import FlatFeeIcon from "../../../../../../assets/img/kuda-flat-fee.inline.svg"
import NotificationIcon from '../../../../../../assets/img/kuda-notification.inline.svg'
import confirmTransferIllustration from "../../../../../../assets/img/business/sendmoney/confirm-transaction-illustration.svg"
import bankTransferIllustration from "../../../../../../assets/img/business/sendmoney/bank-transfer-illustration.svg"
import notificationIllustration from "../../../../../../assets/img/business/sendmoney/notification-illiustration.svg"

import { Img } from "react-image";


const entryContent = {
  title: (<span>
    <span className="color-secondary">Send</span>  money easily with Kuda Business.

  </span>),
  subtitle: "Make single and bulk transfers to as many as 1,000 beneficiaries with the Kuda Business app.",
  name: "Open a Kuda Business Account",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
}

const businessTopFeatures = [
  {
    icon: <SendIcon />,
    text: "Send money to up to 100 beneficiaries at once."
  },
  {
    icon: <FlatFeeIcon />,
    text: "Pay a flat fee of ₦10 on each transfer to other banks."
  },
  {
    icon: <NotificationIcon />,
    text: "Get notifications when your transfers are completed."
  },
]

const payment = {
  title: "Pay one person or many people at once",
  subtitle: "Add up to 100 beneficiaries with a spreadsheet and pay them all at the same time with Kuda Business.",
  name: "Open a Kuda Business Account",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  illustration: (
    <Img src={confirmTransferIllustration} className="kuda-business-image" />

  ),
}

const sendMoney = {
  title: "Save money when you send money.",
  subtitle: "Transfers to Kuda accounts are free, and sending money to other banks will cost you just ₦‎10 per transfer.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Download Kuda Business",
  illustration: (

    <Img src={bankTransferIllustration} className="kuda-business-image" />

  ),
}

const transferCompleted = {
  title: "Know when your transfers are completed.",
  subtitle: "For your peace of mind, you’ll get a notification on the Kuda Business mobile app when each transfer you make gets to the beneficiary bank.",
  name: "Join Kuda Business",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  illustration: (

    <Img src={notificationIllustration} className="kuda-business-image" />

  ),
}


const questions = [
  {
    heading: "What’s the difference between a single transfer and a bulk transfer?",
    list: (
      <span className="flex flex-column  mt-2">

        <span className="faq-description f-16">When you send money to one beneficiary, that’s a single transfer.
          When you send money to two or more beneficiaries at the same time, that’s a bulk transfer.</span>
      </span>),

    index: 1
  },
  {
    heading: "How do I make a bulk transfer on Kuda Business?",
    list: (
      <span className="flex flex-column  mt-2">

        <span className="faq-description f-16">To make a bulk transfer to a maximum of 20 beneficiaries on Kuda Business, follow the steps below:</span>

        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3">1. Sign in to the Kuda Business web or mobile app, then tap Payments.</div>
            <div className="mb-3">2. Tap Create A List if you're sending money to a maximum of 20 beneficiaries. </div>
            <div className="mb-3">3. Tap Who?, then tap Send To New Account and add the details of the beneficiary. Repeat for up to 20 beneficiaries.</div>
            <div className="mb-3">4. Follow the prompts on your screen to complete your bulk transfer.</div>
          </div>
        </span>

        <span className="faq-description f-16">To make a bulk transfer to more than 20 beneficiaries on Kuda Business, follow the steps below:</span>

        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3">1. Sign in to the Kuda Business web app, then tap Payments.</div>
            <div className="mb-3">2. Tap Upload A CSV Or Excel File.</div>
            <div className="mb-3">3. Tap Download Template, then tap Download Bank Codes and add the details of all beneficiaries to the template.</div>
            <div className="mb-3">4. Upload the filled template as a CSV file or an Excel file, then tap Next and follow the prompts on your screen to complete your bulk transfer.</div>
          </div>
        </span>

      </span>),
    index: 2
  },

]

const BusinessSendMoney = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (

    <Fragment>

      <InnerPageHeader
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        buttonName={entryContent.name}
        buttonUrl={entryContent.url}
        entryIllustration={EntryIllustration}
        fallback={<EntryIllustrationBlur/>}
      />
      <DarkBelowFoldCards topFeatures={businessTopFeatures} />
      <ImageDarkTextRightWithButton
        title={payment.title}
        subtitle={payment.subtitle}
        illustration={payment.illustration}
        name={payment.name}
        url={payment.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={sendMoney.title}
        subtitle={sendMoney.subtitle}
        illustration={sendMoney.illustration}
        name={sendMoney.name}
        url={sendMoney.url}
        isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={transferCompleted.title}
        subtitle={transferCompleted.subtitle}
        illustration={transferCompleted.illustration}
        name={transferCompleted.name}
        url={transferCompleted.url}
        isExternal={true}
      />

      <FAQList title={"Transfer FAQs"} questions={questions} />
      <MoreForYouComponent moreForYou={moreForYou2} />
      <CTABusiness />
    </Fragment>
  )
}

export default BusinessSendMoney

